import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { computed } from "mobx";
import MainBody from "./components/Body";
import { Helmet } from "react-helmet";
import Modal from "./components/Modal";
import PricingModal from "./components/PricingModal";
import { observer, inject } from "mobx-react";
import { ViewListIcon } from "@heroicons/react/solid";

@inject("store")
@observer
class Body extends Component {
  state = {
    showModal: false,
    showTrialExpiredModal: false,
    videoSrc: "https://www.youtube.com/embed/tK712pTkVjk?si=Y6P4fN5BzLS8zEmD",
    checkoutUrl: null,
    selectedPlan: "entry",
  };

  async componentDidMount() {
    await this.checkFirstLogin();
    await this.checkTrialStatus();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      await this.checkFirstLogin();
      await this.checkTrialStatus();
    }
  }

  checkFirstLogin = async () => {
    const { store } = this.props;
    await store.refreshTokenAndProfile();

    if (store.profile && !store.profile.firstLoginCompleted) {
      this.setState({ showModal: true });
      await store.api.patch(`/user/${store.profile._id}`, {
        firstLoginCompleted: true,
      });
      store.profile.firstLoginCompleted = true;
    } else {
      this.setState({ showModal: false, videoSrc: "" });
    }
  };

  checkTrialStatus = async () => {
    const { store } = this.props;
    try {
      const response = await store.api.get("/user/check-trial-status");
      if (response.data?.trialEnded) {
        this.setState({
          showTrialExpiredModal: true,
          checkoutUrl: response.data.checkoutUrl,
          selectedPlan: response.data.selectedPlan || this.state.selectedPlan,
        });
      }
    } catch (error) {
      console.error("Failed to check trial status:", error);
    }
  };

  handlePlanChange = async (event) => {
    try {
      const { store } = this.props;
      console.log("Selected plan:", event.target.value);

      const response = await store.api.get("/user/check-trial-status", {
        params: {
          selectedPlan: event.target.value.toLowerCase(),
        },
      });

      if (response.data?.checkoutUrl) {
        console.log("Checkout URL:", response.data.checkoutUrl);
        window.location.href = response.data.checkoutUrl;
      }
    } catch (error) {
      console.error("Failed to check trial status:", error);
    }
  };

  handleTrialExpiredConfirm = () => {
    const { checkoutUrl } = this.state;
    if (checkoutUrl) {
      window.location.href = checkoutUrl;
    }
  };

  handleTrialExpiredClose = () => {
    this.setState({ showTrialExpiredModal: false });
  };

  closeModal = () => {
    this.setState({ showModal: false, videoSrc: "" });
  };

  @computed get permissions() {
    return this.props.store.tools
      .map((tool) => {
        if (
          this.props.store.profile.plan === "entry" &&
          tool.title === "NavixScribe"
        ) {
          tool.title = "NavixScribe (7 days trial)";
          tool.desc =
            "Upgrade to Pro after your 7-day trial to enjoy our latest enhanced NavixScribe! Dictation and transcription. Clinical notes in selected formats. Ask questions related to your content. Generate summaries.";
        }
        return tool;
      })
      .filter((tool) => {
        return (
          this.props.store.hasCurrentToolAccess(tool.to) &&
          tool.permissions.some((permission) =>
            this.props.store.profile.permissions.includes(permission)
          )
        );
      });
  }

  @computed get isAdmin() {
    return this.props.store.profile.accountType === "admin";
  }

  @computed get adminTools() {
    return this.props.store.adminTools;
  }

  @computed get beta() {
    return this.permissions.filter((tool) => tool.category === "Beta");
  }

  @computed get business() {
    return this.permissions.filter((tool) => tool.category === "Business");
  }

  @computed get advisor() {
    const advisors = this.permissions
      .filter((tool) => {
        const hidden = ["Treatment Planning", "Compliance", "Human Resource"];

        return tool.category === "Advisor" && !hidden.includes(tool.title);
      })
      .map((tool) => {
        if (tool.title === "Behavioral Health") {
          tool.title = "Advisors & Resource Libraries";
          tool.desc =
            "Chat with our AI Advisors on topics including Behavioral Health, Compliance, Treatment Planning and Human Resources.";
        }

        return tool;
      });

    return advisors;
  }

  @computed get clinical() {
    const clinicalTools = this.permissions.filter(
      (tool) => tool.category === "Clinical"
    );

    const navixScribeV2 = {
      title: "Scribe + Interact",
      desc: "Transcribes encounters, writes notes, processes PDFs, summarizes, codes encounters, and assists with diagnoses—automating your workflow.",
      category: "Clinical",
      to: "/ai/audio/navixscribe-v2",
    };

    return [navixScribeV2, ...clinicalTools];
  }

  @computed get content() {
    return this.permissions.filter((tool) => tool.category === "Content");
  }

  @computed get rcm() {
    return this.permissions.filter((tool) => tool.category === "RCM");
  }

  @computed get research() {
    return this.permissions.filter((tool) => tool.category === "Research");
  }

  @computed get audio() {
    return this.permissions.filter((tool) => tool.category === "Audio");
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{`Tools - NavixAI`}</title>
        </Helmet>
        <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12 ">
          <Modal
            variant="video"
            open={this.state.showModal}
            onClose={this.closeModal}
          >
            <h2 className="text-xl font-bold mb-2">Welcome to Navix AI!</h2>
            <p className="mb-2">Watch this video to get started:</p>
            <div
              className="relative w-full"
              style={{
                paddingBottom: "56.25%",
                height: 0,
                overflow: "hidden",
                maxWidth: "100%",
                background: "#000",
              }}
            >
              <iframe
                src={this.state.videoSrc}
                frameBorder="0"
                allowFullScreen
                title="NavixScribe guide"
                className="absolute top-0 left-0 w-full h-full"
                style={{ border: 0 }}
              ></iframe>
            </div>
          </Modal>

          <PricingModal
            isOpen={this.state.showTrialExpiredModal}
            onClose={this.handleTrialExpiredClose}
            onConfirm={this.handleTrialExpiredConfirm}
            selectedPlan={this.state.selectedPlan}
            onPlanChange={this.handlePlanChange}
            checkoutUrl={this.state.checkoutUrl}
          />

          {this.business.length ? (
            <>
              <Title title="Business Management" />
              <Grid>
                {this.business.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {/* {this.advisor.length ? (
            <>
              <Title title="Advisors" />
              <Grid>
                {this.advisor.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null} */}

          {this.clinical.length ? (
            <>
              <Title title="Clinical & Medical" />
              <div className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3">
                {this.clinical.map((tool, index) => (
                  <React.Fragment key={index}>
                    {index === 0 ? (
                      <BetaTool {...tool} />
                    ) : (
                      <Tool
                        group={tool.category}
                        title={tool.title}
                        to={tool.to}
                        Icon={tool.Icon}
                        desc={tool.desc}
                        fromColor={tool.fromColor}
                        toColor={tool.toColor}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
              <Divider />
            </>
          ) : null}

          {this.content.length ? (
            <>
              <Title title="CRM & Marketing" />
              <Grid>
                {this.content.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.research.length ? (
            <>
              <Title title="Research & Reference" />
              <Grid>
                {this.research.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.rcm.length ? (
            <>
              <Title title="Revenue Cycle Management (RCM)" />
              <Grid>
                {this.rcm.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.audio.length ? (
            <>
              <Title title="Audio Processor" />
              <Grid>
                {this.audio.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.isAdmin && (
            <>
              <Title title="Admin Tools" />
              <Grid>
                {this.adminTools.map((tool, index) => (
                  <Tool
                    key={index}
                    group="Admin"
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          )}
        </MainBody>
      </>
    );
  }
}

export const Divider = () => (
  <div className="divide-y-2 divide-dashed divide-gray-300 py-4 md:py-6">
    {" "}
    <div></div>
    <div></div>
  </div>
);

export const Title = ({ title }) => (
  <h2 className="text-xl sm:text-2xl md:text-3xl text-gray-700 mb-4 md:mb-6">
    {title}
  </h2>
);

export const Grid = ({ children }) => (
  <div className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 ">
    {children}
  </div>
);

export const Tool = ({ Icon, title, desc, to, group, fromColor, toColor }) => (
  <Link to={to || "/"} className="flex relative ">
    <div
      className={`bg-gray-200 flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-400 md:flex relative transform hover:scale-105  hover:text-black`}
    >
      <div className="p-4">
        <div
          className={`uppercase tracking-wide text-sm text-${fromColor} font-semibold leading-none`}
        >
          {group || "New"}
        </div>
        <div
          href="#"
          className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none"
        >
          {title}
        </div>
        <p className="mt-1 pr-1 text-sm ">{desc} </p>
      </div>
    </div>
  </Link>
);

const BetaTool = ({ title, desc, to, category }) => (
  <Link to={to || "/"} className="flex relative w-full">
    <div className="flex-1 rounded-xl overflow-hidden cursor-pointer relative transform hover:scale-105 transition w-full bg-gradient-to-br from-purple-400 to-indigo-300 p-0.5">
      <div className="h-full w-full bg-white rounded-xl flex flex-col">
        <div className="rounded-t-xl p-2 px-4 bg-gradient-to-r from-purple-200 to-indigo-100">
          <div className="uppercase tracking-wide text-sm text-indigo-800 font-semibold">
            {category}
          </div>
        </div>
        <div className="p-4 bg-white rounded-b-xl flex-grow">
          <div className="block text-xl leading-tight font-bold text-indigo-800 mb-2">
            {title}
          </div>
          <p className="text-sm text-gray-700">{desc}</p>
        </div>
      </div>
    </div>
  </Link>
);

export default withRouter(Body);
